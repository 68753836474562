import React from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"

// Import the image
import ReadyItems from "../../assets/images/procurement/ready item.jpg"

const ReadyItem = () => {
  // Meta title
  document.title = `Ready Item |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Procurement" breadcrumbItem="Ready Items" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1">
                      {"Ready Items "}
                    </h4>
                  </div>
                </CardBody>
                <CardBody>
                  <div>
                  
                    {/* Display the image */}
                    <img
                      src={ReadyItems}
                      alt="Procurement-List"
                      className="img-fluid"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default ReadyItem
