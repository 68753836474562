import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}> © Praavii A Unit of {process.env.REACT_APP_SITE_NAME}. LLC {new Date().getFullYear()} All rights reserved.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
             
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
