import DeleteModal from "components/Common/DeleteModal"
import React, { useEffect, useMemo, useState } from "react"
// import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Link, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Select from "react-select"
import { debounce } from "lodash"
import moment from "moment"

import "react-toastify/dist/ReactToastify.css"
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Button,
  Modal,
  Label,
  Fade,
  Input,
  UncontrolledTooltip,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import {
  getSingleUser,
  getUsers,
  singleUserDelete,
} from "services/UserServices"
import Paginations from "components/Common/Pagination"
import Breadcrumb from "components/Common/Breadcrumb"
import {
  addProductinCollection,
  createCollection,
  getCategoryDropdown,
  getCollectionUsingId,
  getsubCategoryDropdown,
  getsubsubCategoryList,
  updateCollection,
} from "services/MasterServices"
import customStyles from "helpers/custom_Styles"
import {
  deleteProductInCollection,
  getProductDropdown,
} from "services/ProdCollection"
import ImagePopup from "components/Common/ImagePopup"
import DatePicker from "components/Common/DatePicker"
import { useFormik } from "formik"
import * as Yup from "yup"
import CollectionTableContainer from "./CollectionTableContainer"
import TableContainer from "components/Common/TableContainer"

const advanceSearchDefaultData = {
  open: false,
  p_title: null,
  brand: null,
  sku: null,
  category_id: null,
  main_category_id: null,
  sub_sub_category: null,
}
const AddCollection = () => {
  document.title = `Add Collection |  ${process.env.REACT_APP_SITE_NAME} - Admin Portal`
  const [users, setUsers] = useState([]) // Initialize with an empty array
  const [filteredUsers, setFilteredUsers] = useState([]) // State for filtered users
  const navigate = useNavigate()
  const [modal_center, setModalCenter] = useState(false) // Modal state
  const [loading, setLoading] = useState()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [advanceSearch, setAdvanceSearch] = useState(advanceSearchDefaultData)

  const [product, setProduct] = useState("") // State for product input
  const [productOptions, setProductOptions] = useState([]) // Options from the API
  const [productDelete, setproductDelete] = useState()
  const [categoryDropdownList, setCategoryDropdownList] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [subCategoryDropdownList, setSubCategoryDropdownList] = useState([])
  const [subCategoryId, setSubCategoryId] = useState("")
  const [subSubCategoryDropdownList, setSubSubCategoryDropdownList] = useState(
    []
  )
  const [subSubcategoryId, setSubSubCategoryId] = useState("")
  const [collection, setCollection] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([]) // Track selected products
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [searchTerm, setSearchTerm] = useState("") // For managing search input text
  const [collectionId, setCollectionId] = useState()
  const validationCreate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      collection_name: "",
      status: true,
      start_date: "", // Add start_date to initial values
    },
    validationSchema: Yup.object({
      collection_name: Yup.string().trim().required("Enter Collection Name"),
    }),
    onSubmit: async values => {
      if (startDate === "" || startDate === null || startDate === undefined) {
        toast.error("Please Select Start Date", {
          autoClose: 2000,
        })
        return
      } else {
        const payload = {
          collection_name: values["collection_name"].trim(),
          status: values["status"] ? 1 : 0,
          start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        }
        // save new Deduction

        console.log(payload, "payload")

        try {
          const response = await createCollection(payload)

          if (response?.id) {
            setCollectionId(response.id) // Set collection ID
            localStorage.setItem("collectionId", response.id)

            toast.success(`Collection is Created Successfully`, {
              autoClose: 2000,
            })
            console.log(response, "response")
          } else {
            return // Exit the function early
          }
        } catch (err) {
          console.error(err)
          if (err?.status === 400 && err?.response?.data?.message) {
            const errorMessage = err.response.data.message
            toast.error(errorMessage, { autoClose: 2000 })
          } else {
            toast.error("Collection is Adding Failed", {
              autoClose: 2000,
            })
          }
        }
      }
    },
  })
  const handleEdit = async () => {
    console.log("Clicked")
    if (!collectionId) {
      toast.error("No collection to update", {
        autoClose: 2000,
      })
      return
    }
    const collectionName = validationCreate.values.collection_name.trim()
    if (!collectionName) {
      return // Return early if name is missing
    }
    if (startDate === "" || startDate === null || startDate === undefined) {
      toast.error("Please Select Start Date", {
        autoClose: 2000,
      })
      return
    }

    const payload = {
      // id: collectionId, // Include the collection ID for editing
      collection_name: validationCreate.values.collection_name.trim(),
      status: validationCreate.values.status ? 1 : 0,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    }
    console.log(payload, "Edit Payload")
    try {
      await updateCollection(collectionId, payload)
        .then(response => {
          console.log(response, "response")
          toast.success(`Collection is updated Successfully`, {
            autoClose: 2000,
          })
        })
        .catch(err => {
          console.log(err)
          if (err?.status === 400 && err?.response?.data?.message) {
            toast.error(err?.response?.data?.message, { autoClose: 2000 })
          } else {
            toast.error(`Collection is Added Failed`, { autoClose: 2000 })
          }
        })
    } catch (error) {
      toast.error(`Collection is updated Failed`, { autoClose: 2000 })
    }
  }
  const handleSaveCollection = () => {
    validationCreate.handleSubmit()
  }
  const toggle = () => {
    setModal(!modal) // Toggle the modal state
  }

  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "id",

        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => (
          <Link to="" className="text-body fw-bold">
            {cellProps.row.index + 1}
          </Link>
        ),
      },
      {
        header: "Image",
        accessorKey: "productMedia",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const mediaArray = cellProps.row.original.products.productMedia || []
          const primaryMedia = mediaArray.find(media => media.is_primary === 1)

          const displayMedia = primaryMedia
          if (displayMedia && displayMedia.media_type?.includes("image")) {
            return (
              <div style={{ maxWidth: "50px" }}>
                <ImagePopup
                  src={`${process.env.REACT_APP_STORAGEPATH}/product_media/${displayMedia.file_name}`}
                  className="img-thumbnail"
                  alt="Product"
                />
              </div>
            )
          } else {
            return (
              <div
                className="img-thumbnail"
                style={{
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgb(245, 245, 249)",
                  border: "1px solid rgb(214, 214, 214)",
                }}
              >
                <span
                  style={{
                    fontSize: "10px",
                    color: "rgb(169, 169, 169)",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  No Image
                </span>
              </div>
            )
          }
        },
      },
      {
        header: "Product Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => <div>{row.original.products.p_title}</div>,
      },
      // {
      //   header: () => <div style={{ textAlign: "center" }}>Price</div>,
      //   accessorKey: "name",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: ({ row }) => (
      //     <div style={{ textAlign: "right" }}>
      //       {" "}
      //       ₹{row.original.products.price}{" "}
      //     </div>
      //   ),
      // },
      // {
      //   header: () => <div style={{ textAlign: "right" }}>Sell Price</div>,
      //   accessorKey: "name",
      //   enableColumnFilter: false,
      //   enableSorting: false,

      //   cell: ({ row }) => (
      //     <div style={{ textAlign: "right" }}>
      //       {" "}
      //       ₹{row.original.products.sale_price}{" "}
      //     </div>
      //   ),
      // },
      // {
      //   header: () => <div style={{ textAlign: "right" }}>Cose Price</div>,
      //   accessorKey: "name",
      //   enableColumnFilter: false,
      //   enableSorting: false,

      //   cell: ({ row }) => (
      //     <div style={{ textAlign: "right" }}>
      //       {" "}
      //       ₹{row.original.products.cost_price}{" "}
      //     </div>
      //   ),
      // },
      // {
      //   header: "Status",
      //   accessorKey: "status",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: ({ row }) => {
      //     const status = row.original.products.status // Get user status
      //     return (
      //       <span
      //         className={`badge ${
      //           status === 1 ? "badge-soft-success" : "badge-soft-danger"
      //         }`}
      //       >
      //         {status === 1 ? "Active" : "Inactive"}
      //       </span>
      //     )
      //   },
      // },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "user-action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => (
          <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
            <li></li>

            <li>
              <Link
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  const jobData = cellProps.row.original.product_id
                  onClickDelete(jobData)
                }}
                id={`deletetooltip-${cellProps.row.original.id}`}
              >
                <i className="mdi mdi-delete-outline" />
                <UncontrolledTooltip
                  placement="top"
                  target={`deletetooltip-${cellProps.row.original.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    [page]
  )

  const onClickDelete = id => {
    setproductDelete(id)
    setDeleteModal(true)
  }

  console.log("Advance Search---", advanceSearch)
  const handleSearchProduct = async () => {
    toggle()
    let params = {
      status: 1,
      limit: 200,
      page: 1,
      collection_type: "not",
      collection_id: collectionId,
    }

    if (advanceSearch) {
      const advanceSearchParams = formatAdvanceSearchData()
      params = {
        ...params,
        ...advanceSearchParams,
      }
      console.log("advanceSearchparam391---", params)
    }
    

    try {
      const response = await getProductDropdown({ params }) // API call
      const productData = response?.data || [] // Ensure a valid array is returned
      console.log("Search Results:", productData)
      setProductOptions(productData) // Update options
    } catch (error) {
      console.error("Error fetching products:", error)
    }
  }

  const handleCheckboxChange = productId => {
    console.log("Checkbox clicked for productId:", productId)

    setSelectedProducts(prevSelected => {
      const updatedSelection = prevSelected.includes(productId)
        ? prevSelected.filter(id => id !== productId) // Remove if already selected
        : [...prevSelected, productId] // Add if not selected

      console.log("Updated Selected Products:", updatedSelection) // Debug updated state
      return updatedSelection
    })
  }
  console.log("selectedProducts:", selectedProducts)
  useEffect(() => {
    getCategoryList()
    getTableProductList()
  }, [])

  // Update product and trigger API call

  const getTableProductList = async () => {
    console.log("ID---", collectionId)

    try {
      const response = await getCollectionUsingId(collectionId)
      console.log("get Product List", response)

      if (response) {
        let arr = response

        setFilteredUsers(response?.productCollections)
      }
    } catch (error) {
      console.error("Error fetching product list:", error)
    }
  }

  const handleReset = () => {
    setProduct("")
    setProductOptions([])
    setSelectedProducts([])
    setSearchTerm("")
    setProductOptions([])
    setCategoryId("")
    setSubCategoryId("")
    setSubSubCategoryId("")
    console.log("handle Clicked--RESET")
    setAdvanceSearch(advanceSearchDefaultData)
    setAdvanceSearch({
      ...advanceSearchDefaultData, 
      p_title: "" // Explicitly reset the Product Title
    });
  }
  const handleCategory = e => {
    setAdvanceSearch(prev => ({
      ...prev,
      main_category_id: e,
      category_id: null,
      sub_sub_category: null,
    }))

    setSubCategoryDropdownList([])
    setSubSubCategoryDropdownList([])

    if (e) {
      getSubcategoryList(e?.value)
    }
  }

  const getCategoryList = async () => {
    let params = {
      status: 'active',
      parent_type: "parent",
      type: 1,
    }

    try {
      await getCategoryDropdown({ params })
        .then(response => {
          console.log(response)

          if (response) {
            let temp = response
            setCategoryDropdownList(
              temp?.map(item => ({
                ...item,
                label: item.category_name,
                value: item.id,
              }))
            )
          }
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const getSubSubcategoryList = async subsubcatId => {
    let params = {
      status: 'active',
      parent_id: subsubcatId,
      type: 3,
    }

    try {
      await getCategoryDropdown({ params })
        .then(response => {
          console.log(response)

          if (response) {
            let temp = response
            setSubSubCategoryDropdownList(
              temp?.map(item => ({
                ...item,
                label: item.category_name,
                value: item.id,
              }))
            )
          }
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }
  const getSubcategoryList = async subcatId => {
    let params = {
      status: 'active',
      parent_id: subcatId,
      type: 2,
    }

    try {
      await getCategoryDropdown({ params })
        .then(response => {
          console.log(response)

          if (response) {
            let temp = response
            setSubCategoryDropdownList(
              temp?.map(item => ({
                ...item,
                label: item.category_name,
                value: item.id,
              }))
            )
          }
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const formatAdvanceSearchData = () => {
    console.log(advanceSearch, "advanceSearch")
    return {
      p_title: advanceSearch.p_title ? advanceSearch.p_title.trim() : null,
      cat_id:
        //sub subcategory
        advanceSearch.sub_sub_category && advanceSearch?.sub_sub_category?.value
          ? advanceSearch.sub_sub_category?.value
          : // subcategory
          advanceSearch.category_id && advanceSearch?.category_id?.value
          ? advanceSearch.category_id?.value
          : // category
          advanceSearch.main_category_id &&
            advanceSearch?.main_category_id?.value
          ? advanceSearch.main_category_id?.value
          : null,

      // parent_id:
      //   advanceSearch.main_category_id && advanceSearch?.main_category_id?.value
      //     ? advanceSearch.main_category_id?.value
      //     : null,
    }
  }

  const handleProdCat = async () => {
    console.log("Clicked Done")
    console.log("Selected Products list====", selectedProducts)
    const payload = selectedProducts.map(productId => ({
      collection_id: collectionId,
      product_id: productId,
    }))
    console.log("Product Submit under Collection Payload--", payload)

    try {
      // Retrieve access token from local storage
      const authUser = JSON.parse(localStorage.getItem("authUser"))
      const accessToken = authUser?.access_token
      // console.log("Access token retrieved:", accessToken)

      // Check if access token exists
      if (!accessToken) {
        console.error("Access token not found. Please log in again.")
        throw new Error("Access token not found. Please log in again.")
      }

      // Make the POST request to update user info
      const response = await fetch(
        `${process.env.REACT_APP_BASEPATH}/collection/multi-products`,
        {
          method: "POST", // Use the appropriate method
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Pass the Bearer token
          },
          body: JSON.stringify(payload), // Send updated values
        }
      )

      // Log the response status
      console.log("Response status:", response)

      // Check if response is ok
      if (!response.ok) {
        const errorMessage = "Failed to product data"
        console.error("API response error:", errorMessage)
        throw new Error(errorMessage) // Throw error if response is not ok
      } else {
        toggle()
        handleReset()
        getTableProductList()
      }
      toast.success("Product added successfully.", { autoClose: 1500 })

      // Optionally handle the response
      const result = await response.json()
      console.log("Update response:", result)
      // Update form values with the updated data

      // Set success message
    } catch (error) {
      toast.error("Failed to add Product. Please try again.", {
        autoClose: 1500,
      })

      console.error("Error while adding Product:", error.message)
    }
  }

  const handleDeleteProduct = async () => {
    console.log("Product ID FOR DELETE", productDelete)
    console.log("Category ID FOR DELETE", collectionId)
    try {
      await deleteProductInCollection(collectionId, productDelete)
        .then(response => {
          console.log(response, "response")
          getTableProductList()
          setDeleteModal(false)
          toast.success(`Product is Deleted Successfully`, {
            autoClose: 2000,
          })
        })
        .catch(err => {
          console.log(err)

          toast.error(`Product is not Deleted Successfully`, {
            autoClose: 2000,
          })
        })
    } catch (error) {
      toast.error(` Product is not Deleted Successfully`, { autoClose: 2000 })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb
            title="Collections"
            to={`/collections`}
            breadcrumbItem="Add Collection"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom1">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 flex-grow-1">Manage Collections</h4>
                    <div className="flex-shrink-0">
                      <div>
                        <Link
                          to="/collections"
                          className="text-primary"
                          onClick={() =>
                            localStorage.removeItem("collectionId")
                          }
                        >
                          <i className="mdi mdi-arrow-left" /> Go Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>

                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationCreate.handleSubmit()
                      return false
                    }}
                  >
                    {/* Row containing the three form fields */}
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="collection_name"
                            type="text"
                            placeholder="Enter name"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validationCreate.handleChange}
                            onBlur={validationCreate.handleBlur}
                            value={
                              validationCreate.values.collection_name || ""
                            }
                            invalid={
                              validationCreate.touched.collection_name &&
                              validationCreate.errors.collection_name
                                ? true
                                : false
                            }
                          />
                          {validationCreate.touched.collection_name &&
                          validationCreate.errors.collection_name ? (
                            <FormFeedback type="invalid">
                              {validationCreate.errors.collection_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-reg_date-Input">
                            Start Date <span className="text-danger">*</span>
                          </Label>
                          <DatePicker
                            date={startDate}
                            onDateChange={date => {
                              setStartDate(date)
                              console.log("Selected date:", date)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-reg_date-Input">
                            End Date
                          </Label>
                          <DatePicker
                            date={endDate}
                            onDateChange={date => {
                              setEndDate(date)
                              console.log("Selected End date:", date)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Status</Label>
                          <FormGroup switch>
                            <Label>
                              <Input
                                name="status"
                                type="switch"
                                role="switch"
                                checked={validationCreate.values.status}
                                onClick={e =>
                                  validationCreate.setFieldValue(
                                    "status",
                                    !validationCreate.values.status
                                  )
                                }
                                onBlur={validationCreate.handleBlur}
                              />
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>

                      {collectionId ? (
                        <Col
                          md={2}
                          className="mt-3 d-flex align-items-center justify-content-end"
                        >
                          <Button color="primary" onClick={handleEdit}>
                            <i className="mdi mdi-content-save" />
                            &nbsp; Update &nbsp;&nbsp;
                          </Button>
                        </Col>
                      ) : (
                        <Col
                          md={2}
                          className="mt-3 d-flex align-items-center justify-content-end"
                        >
                          <Button
                            color="primary"
                            onClick={handleSaveCollection}
                          >
                            <i className="mdi mdi-content-save" />
                            &nbsp; Save &nbsp;&nbsp;
                          </Button>
                        </Col>
                      )}
                    </Row>

                    {/* Row For Productssssssssssssssssssssss Search */}
                    {collectionId ? (
                      <Row className="my-2">
                        <Col md={12} className="shadow  border p-2 rounded">
                          <div className="mt-2 d-flex align-items-center">
                            <h5 className="mb-0 card-title flex-grow-1">
                              Choose Product
                            </h5>
                            {/* <div className="flex-shrink-0">
                              <Button
                                type="secondary"
                                id={`client-gloabal-search`}
                              >
                                <i className="mdi mdi-magnify" />

                                <UncontrolledTooltip
                                  placement="top"
                                  target={`client-gloabal-search`}
                                >
                                  Search
                                </UncontrolledTooltip>
                              </Button>
                            </div> */}
                          </div> 
                        </Col>
                        <Col md={12}>
                          <div className="mt-3">
                            <Label>Product Title</Label>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <Input
                                type="text"
                                name="search"
                                value={advanceSearch.p_title} // Bind searchTerm to input field
                                onChange={e =>
                                  setAdvanceSearch(prev => ({
                                    ...prev,
                                    p_title: e.target.value,
                                  }))
                                } // Trigger search when user types
                                placeholder="Keyword Search"
                                style={{
                                  width: "100%", // Make the input full width
                                  paddingRight: "25px", // Add padding to make space for the "X" button
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="mt-3">
                          <Label>Category</Label>

                          <Select
                            value={advanceSearch.main_category_id}
                            styles={customStyles()}
                            placeholder="Select"
                            onChange={handleCategory}
                            options={categoryDropdownList}
                            className="select2-selection"
                            isClearable
                          />
                        </Col>
                        <Col lg={3} className="mt-3">
                          <Label>Subcategory</Label>

                          <Select
                            placeholder="Select"
                            value={advanceSearch.category_id}
                            styles={customStyles()}
                            onChange={select => {
                              setAdvanceSearch(prev => ({
                                ...prev,
                                category_id: select,
                                sub_sub_category: null,
                              }))
                              setSubSubCategoryDropdownList([])
                              if (select) {
                                getSubSubcategoryList(select.value)
                              }
                            }}
                            options={subCategoryDropdownList}
                            className="select2-selection"
                            isClearable
                          />
                        </Col>
                        <Col lg={3} className="mt-3">
                          <Label>Sub subcategory</Label>

                          <Select
                            placeholder="Select"
                            value={advanceSearch.sub_sub_category}
                            styles={customStyles()}
                            onChange={select =>
                              setAdvanceSearch(prev => ({
                                ...prev,
                                sub_sub_category: select,
                              }))
                            }
                            options={subSubCategoryDropdownList}
                            className="select2-selection"
                            isClearable
                          />
                        </Col>

                        <Col
                          md={3}
                          className="mt-5 d-flex align-items-center justify-content-end"
                        >
                          <Button
                            color="danger"
                            outline
                            className="me-1"
                            id="user-cancel"
                            onClick={() => handleReset()}
                          >
                            <i className="mdi mdi-close" />
                            <UncontrolledTooltip target={`user-cancel`}>
                              Cancel
                            </UncontrolledTooltip>
                          </Button>
                          <Button color="primary" onClick={handleSearchProduct}>
                            <i className="mdi mdi-cloud-search" /> &nbsp;
                            Search&nbsp;&nbsp;
                          </Button>
                        </Col>
                      </Row>
                    ) : null}

                    <TableContainer
                      loading={loading}
                      setLoading={setLoading}
                      columns={columns}
                      data={filteredUsers} // Show all filtered users
                      isPagination={false}
                      // itemsPerPage={10}
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />

                    {/* <Row>
                      <Col>
                        <div className="text-end">
                          <Button
                            color="primary"
                            // type="submit"
                            className="save-user"
                          >
                            <i className="mdi mdi-content-save-outline" />
                            &nbsp; Submit
                          </Button>
                        </div>
                      </Col>
                    </Row> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          isOpen={modal}
          toggle={() => {
            toggle()
            // validationCreate.resetForm() // Reset form on close
          }}
          className="modal-lg"
        >
          <ModalHeader
            toggle={() => {
              toggle()
              // validationCreate.resetForm() // Reset form on close
            }}
            tag="h5"
          >
            Choose Product
          </ModalHeader>
          <Form
            onSubmit={e => {
              e.preventDefault()
              // validationCreate.handleSubmit()
              return false
            }}
          >
            {productOptions.length > 0 ? (
              <div
                className="dropdown-menu show"
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  width: "100%",
                  maxHeight: "450px",
                  overflowY: "auto",
                  zIndex: 1050,
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "space-between",
                }}
              >
                {/* Product Options */}
                <div style={{ flex: 1, overflowY: "auto" }}>
                  {productOptions.map(option => {
                    const primaryMedia =
                      option?.productMedia?.find(
                        media => media.is_primary === 1
                      ) || null

                    return (
                      <div
                        key={option?.id}
                        className="dropdown-item d-flex align-items-center"
                      >
                        <Input
                          type="checkbox"
                          className="form-check-input me-2"
                          // checked={selectedProducts.includes(
                          //   option.id
                          // )} // Check if selected
                          // checked={selectedProducts.includes(option.id)} // Check if selected
                          onChange={() => handleCheckboxChange(option.id)} // Toggle selection
                        />
                        {primaryMedia ? (
                          <img
                            src={`${process.env.REACT_APP_STORAGEPATH}/product_media/${primaryMedia.file_name}`}
                            className="img-thumbnail"
                            width="50"
                            height="50"
                            alt="Product"
                          />
                        ) : (
                          <div
                            className="img-thumbnail"
                            style={{
                              width: "50px",
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "rgb(245, 245, 249)",
                              border: "1px solid rgb(214, 214, 214)",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10px",
                                color: "rgb(169, 169, 169)",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                              }}
                            >
                              N/A
                            </span>
                          </div>
                        )}
                        <span>{option.p_title}</span>
                      </div>
                    )
                  })}
                </div>

                {/* Sticky Buttons */}
                <div
                  style={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#fff",
                    borderTop: "1px solid #ddd",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "flex-end", // Align to the right
                    gap: "10px", // Add space between buttons
                  }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={handleProdCat}
                    // type="submit" // Ensures the button triggers the form submission
                  >
                    Done
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      toggle()
                      // handleReset()
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div
                  className="dropdown-menu show"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    zIndex: 1050,
                  }}
                >
                  <div className="dropdown-item text-center text-muted">
                  No Record Available
                  </div>
                </div>
              </>
            )}
            {/* <Row>
                <Col>
                  <div className="text-end">
                    <Button color="primary" type="submit" className="save-user">
                      <i className="mdi mdi-content-save-outline" />
                      &nbsp; Submit
                    </Button>
                  </div>
                </Col>
              </Row> */}
          </Form>
        </Modal>
        <ToastContainer position="top-right" autoClose={1500} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteProduct}
          onCloseClick={() => setDeleteModal(false)}
          mainHeader="Are you sure you want to delete this deduction Reason?"
        />
      </div>
    </React.Fragment>
  )
}

export default AddCollection
