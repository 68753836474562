import moment from "moment/moment";

export const get_file_full_path = (asset) => {
  return process.env.REACT_APP_STORAGEPATH + `/product_media/${asset}`
}

export const  generateUniqueNumber = (existingArray,min=100, max=1000) =>{
  while (true) {
  //   const randomNumber =  Math.floor(Math.random() * (30 - 20)) + 20;

    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    if (!existingArray.find(item=>item.id === randomNumber)) {
      return randomNumber;
    }
  }
};

export const formatDate = (date, date_format=process.env.REACT_APP_DATE_FORMAT) => {
  const formated_date = moment(date).format(date_format)
  return date ? (formated_date == 'Invalid date' ? null : formated_date) : null
}

export const makeid = (length = 20) => {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}


export const paymentTypeList = [
  {
    label: "Sale",
    value: "s"
  },
  {
    label: "Purchase",
    value: "p"
  },
  {
    label: "Refund",
    value: "r"
  }
];


export const accountTypeList = [
  {
    label: "Praavii",
    value: "1"
  },
  {
    label: "Vendor",
    value: "2"
  },
  {
    label: "Client",
    value: "3"
  }
];

export const currencySymbol = () =>process.env.REACT_APP_CURRENCY_SYMBOL || '₹'

export const formatPrice = (price) => `${currencySymbol()}${price}`;

export const limitList = [10,25,50,75];


const  a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
const b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

export const inWords = (num) => {
    if ((num = num.toString()).length > 9) return 'overflow';
  let  n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
}

/**
 * Calculate GST values for a given price
 * @param {number} price - The unit price including GST
 * @param {boolean} [is_include_gst=true] - Whether the price includes GST
 * @param {number} [quantity=1] - Quantity of items
 * @param {number} [lower_bound=1000] - Lower bound for GST percentage
 * @param {number} [lower_gst_pct=5] - GST percentage for prices below the lower bound
 * @param {number} [upper_gst_pct=12] - GST percentage for prices above the lower bound
 * @returns {Object} An object containing IGST, CGST, and SGST values
 */
export const calculate_gst_values = (
  price, 
  is_include_gst = true,
  quantity,
  lower_bound,
  lower_gst_pct, 
  upper_gst_pct
) => {
  // Normalize price to a number
  price = Number(price);
  
  // Validate inputs
  if (isNaN(price) || price < 0 || isNaN(quantity) || quantity < 1) {
    return {
      gst_pct: 0,
      igst: 0,
      cgst: 0,
      sgst: 0,
      basePrice: 0,
      totalPrice: 0
    }
  }

  // Calculate total price based on quantity
  const totalPrice = price * quantity;

  // Determine GST percentage based on unit price
  const gst_pct = price >= lower_bound ? upper_gst_pct : lower_gst_pct;
  
  // Calculate values based on whether GST is included
  if (is_include_gst) {
    // Convert rates to decimals
    const rateMap = {
      igst: 100 / (gst_pct + 100),
    };
    const basePrice = totalPrice * rateMap.igst;
    const gst_rate = totalPrice - basePrice
    
    return {
      gst_pct,
      igst: Number(gst_rate.toFixed(2)),
      cgst: Number(gst_rate / 2).toFixed(2),
      sgst: Number(gst_rate / 2).toFixed(2),
      basePrice: Number(basePrice.toFixed(2)),
      totalPrice
    };
  } else {
    const rateMap = {
      igst: (gst_pct + 100) / 100,
    };
    // Price does not include GST
    const basePrice = totalPrice * rateMap.igst;
    const gst_rate =  basePrice - totalPrice

    return {
      gst_pct,
      igst: Number((gst_rate).toFixed(2)),
      cgst: Number((gst_rate/2).toFixed(2)),
      sgst: Number((gst_rate/2).toFixed(2)),
      basePrice: basePrice,
      totalPrice: Number((totalPrice + gst_rate).toFixed(2))
    };
  }
};


// Dropdown for Custom Product
export const CUSTOM_DROPDOWN_MENU = { value: 'custom', label: 'Custom' }