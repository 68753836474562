import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Button,
  FormGroup,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  updateGST,
  getGSTList,
  createGST,
  deleteGst,
} from "services/MasterServices"

const Gst = () => {
  //meta title
  document.title = `GST Classes |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [deduction, setDeduction] = useState(null)
  const [deductionList, setDeductionList] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([]) // Filtered data state
  const [searchTerm, setSearchTerm] = useState("") // State for search term
  const [searchQuery, setSearchQuery] = useState("") // State to store the lowercase search query
 
 
  const gstValidationSchema = Yup.object({
    gst_class_name: Yup.string().trim().required("Enter GST Class Name"),
    percentage: Yup.number()
      .typeError("Percentage must be a valid number")
      .min(0, "Percentage cannot be negative")
      .max(100, "Percentage cannot exceed 100")
      .test(
        "is-decimal",
        "Percentage can only have up to 2 decimal points",
        value => /^\d+(\.\d{1,2})?$/.test(value) || value === undefined
      )
      .required("Enter a percentage"),
  });
  // validation
  const validationCreate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      gst_class_name: "",
      percentage: "",
      status: true,
    },
    validationSchema: gstValidationSchema,

    onSubmit: async values => {
        const payload = {
            gst_class_name: values["gst_class_name"].trim(),
            percentage: parseFloat(values["percentage"]),
            status: values["status"] ? 1 : 0,
          };
      // save new Deduction

      console.log(payload, "payload")

        try {
          await createGST(payload)
            .then(response => {
              console.log(response, "response")
              toast.success(`GST Class is Added Successfully`, {
                autoClose: 2000,
              })
              validationCreate.resetForm()
              getList()
              toggle()
            })
            .catch(err => {
              console.log(err)
              if (err?.status === 400 && err?.response?.data?.message) {
                const errorMessage = err?.response?.data?.message
                toast.error(errorMessage, { autoClose: 2000 })
                // Check for the specific error message
              //   if (errorMessage === "Deduction reason title already exists.") {
              //     toast.error("Duplicate Payment Deduction Reason", {
              //       autoClose: 2000,
              //     })
              //   } else {
              //     // Show the default error message if it doesn't match
              //     toast.error(errorMessage, { autoClose: 2000 })
              //   }
              } else {
                toast.error("GST Class is Adding Failed", {
                  autoClose: 2000,
                })
              }
            })
        } catch (error) {
          toast.error(`GST Class is Added Failed`, { autoClose: 2000 })
        }
    },
  })

  const validationEdit = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
  
    initialValues: {
      id: (deduction && deduction.id) || "",
      gst_class_name: (deduction && deduction.gst_class_name) || "",
      percentage: (deduction && deduction.percentage) || "",
      status: deduction && deduction.status === 1 ? true : false,
    },
  
    validationSchema: gstValidationSchema,

  
    onSubmit: async (values) => {
      const payload = {
        id: values["id"],
        gst_class_name: values["gst_class_name"].trim(),
        percentage: parseFloat(values["percentage"]),
        status: values["status"] ? 1 : 0,
      };
      
      // update Job
      try {
        await updateGST(values["id"], payload)
          .then((response) => {
            console.log(response, "response");
            toast.success(`GST Class is updated Successfully`, { autoClose: 2000 });
            validationEdit.resetForm();
            getList();
            toggleEdit();
          })
          .catch((err) => {
            console.log(err);
            if (err?.status === 400 && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message, { autoClose: 2000 });
            } else {
              toast.error(`GST Class update failed`, { autoClose: 2000 });
            }
          });
      } catch (error) {
        toast.error(`GST Class update failed`, { autoClose: 2000 });
      }
    },
  });
  

  const [loading, setLoading] = useState(false)

//   const handleSearch = e => {
//     const userInput = e.target.value // User's raw input
//     const lowercaseQuery = userInput.toLowerCase() // Lowercase for filtering

//     setSearchTerm(userInput) // Preserve the original input
//     setSearchQuery(lowercaseQuery) // Store the lowercase version for filtering

//     if (lowercaseQuery.trim() === "") {
//       setFilteredUsers(deductionList) // Reset to full list if search is cleared
//     } else {
//       const filtered = deductionList.filter(deduction =>
//         deduction.gst_class_name.toLowerCase().includes(lowercaseQuery)
//       )
//       setFilteredUsers(filtered)
//     }
//   }
const handleSearch = e => {
    const userInput = e.target.value; // User's raw input
    const lowercaseQuery = userInput.toLowerCase(); // Lowercase for filtering
  
    setSearchTerm(userInput); // Preserve the original input
    setSearchQuery(lowercaseQuery); // Store the lowercase version for filtering
  
    if (lowercaseQuery.trim() === "") {
      setFilteredUsers(deductionList); // Reset to full list if search is cleared
    } else {
      const filtered = deductionList.filter(deduction => 
        deduction.gst_class_name.toLowerCase().includes(lowercaseQuery) || 
        deduction.percentage.toString().includes(lowercaseQuery)
      );
      setFilteredUsers(filtered); // Update filtered list
    }
  };
  
  useEffect(() => {
    getList()
  }, [])

  const getList = async () => {
    try {
      let params = {
        // parent_type: "parent",
      }

      setLoading(true)

      await getGSTList({ params })
        .then(response => {
          console.log(response)

          if (response) {
            let arr = response

            if (arr.find(item => item.id === 1)) {
              // Remove the first element and store it
              const firstElement = arr.shift()

              // Add the removed element to the end of the array
              // arr.push(firstElement);
            }

            setDeductionList(arr)
            setFilteredUsers(arr)
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)

          setLoading(false)
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setDeduction(null)
    } else {
      setModal(true)
    }
  }

  const toggleEdit = () => {
    if (modalEdit) {
      setModalEdit(false)
      setDeduction(null)
      validationEdit.resetForm()
    } else {
      setModalEdit(true)
    }
  }

  const handleEditClick = arg => {
    const deduction = arg
    setDeduction(deduction)

    toggleEdit()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = deduction => {
    setDeduction(deduction)
    setDeleteModal(true)
  }

  const handleDeleteCategory = async () => {
    if (deduction && deduction.id) {
      try {
        await deleteGst(deduction.id)
          .then(response => {
            console.log(response, "response")
            getList()
            setDeleteModal(false)
            toast.success(`GST Class is Deleted Successfully`, {
              autoClose: 2000,
            })
          })
          .catch(err => {
            console.log(err)

            toast.error(`GST Class is Deleted Failed`, {
              autoClose: 2000,
            })
          })
      } catch (error) {
        toast.error(` Deduction is Deleted Failed`, { autoClose: 2000 })
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <Link to="" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
      },
      {
        header: "Class Name",
        accessorKey: "gst_class_name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Percentage", // Header for percentage column
        accessorKey: "percentage", // Key for percentage field
        enableColumnFilter: false,
        enableSorting: false,
        size: 120, // Max width for the column, if supported by your table library
        cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
              maxWidth: "90px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap", // Ensures content is truncated
            }}
          >
           {row.original.percentage}%
          </div>
        ), // Format to 2 decimal points with right alignment
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "deduction-action",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to=""
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleEditClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
        mainHeader="Are you sure you want to delete this deduction Reason?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Master" breadcrumbItem="GST Classes" />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h4 className="mb-0 card-title flex-grow-1">GST Classes </h4>

                      <div className="flex-shrink-0">
                        <Link
                          to=""
                          onClick={() => setModal(true)}
                          className="btn btn-primary me-1 same-search-btn add"
                        >
                          {" "}
                          <i className="mdi mdi-plus" />
                          Add
                        </Link>
                        {/* <Button type="secondary">
                          <i className="mdi mdi-magnify" />
                        </Button> */}
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col sm={4}>
                        <Input
                          type="text"
                          placeholder="Keyword Search"
                          value={searchTerm}
                          onChange={handleSearch} // Call search on input change
                          className="form-control search-box me-2 mb-2 d-inline-block"
                        />
                      </Col>
                    </Row>
                    {/* Initially below and above code was not there due to pagination implemented */}
                    {/* <Row className="mb-2">
                      <Col md={10}>
                        <Input
                          type="text"
                          placeholder="Keyword Search"
                          value={searchTerm}
                          onChange={handleSearch} // Call search on input change
                          className="me-2 w-25"
                        />
                      </Col>
                    </Row>{" "} */}

                    <TableContainer
                      columns={columns}
                      data={filteredUsers || []}
                      // isCustomPageSize={true}
                      // isGlobalFilter={true}
                      // isJobListGlobalFilter={true}
                      loading={loading}
                      setLoading={setLoading}
                      isPagination={true}
                      itemsPerPage={10}
                      // SearchPlaceholder="Keyword Search"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal
            isOpen={modal}
            toggle={() => {
              toggle()
              validationCreate.resetForm() // Reset form on close
            }}
          >
            <ModalHeader
              toggle={() => {
                toggle()
                validationCreate.resetForm() // Reset form on close
              }}
              tag="h4"
            >
              Add GST Class
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationCreate.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gst_class_name"
                        type="text"
                        placeholder="Enter name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.gst_class_name || ""}
                        invalid={
                          validationCreate.touched.gst_class_name &&
                          validationCreate.errors.gst_class_name
                            ? true
                            : false
                        }
                      />
                      {validationCreate.touched.gst_class_name &&
                      validationCreate.errors.gst_class_name ? (
                        <FormFeedback type="invalid">
                          {validationCreate.errors.gst_class_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>
                        Percentage <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="percentage"
                        type="number"
                        placeholder="Enter percentage"
                        step="0.01"
                        onChange={validationCreate.handleChange}
                        onBlur={validationCreate.handleBlur}
                        value={validationCreate.values.percentage || ""}
                        invalid={
                          validationCreate.touched.percentage &&
                          validationCreate.errors.percentage
                            ? true
                            : false
                        }
                      />
                      {validationCreate.touched.percentage &&
                      validationCreate.errors.percentage ? (
                        <FormFeedback type="invalid">
                          {validationCreate.errors.percentage}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {" "}
                          {/* {validationCreate.values.status === true ? "" : ""} */}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationCreate.values.status === true}
                            onClick={validationCreate.handleChange}
                            onBlur={validationCreate.handleBlur}
                            value={validationCreate.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        <i className="mdi mdi-content-save-outline" />
                        &nbsp; Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit} tag="h4">
              Edit GST Class
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationEdit.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gst_class_name"
                        type="text"
                        placeholder="Enter name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validationEdit.handleChange}
                        onBlur={validationEdit.handleBlur}
                        value={validationEdit.values.gst_class_name || ""}
                        invalid={
                          validationEdit.touched.gst_class_name &&
                          validationEdit.errors.gst_class_name
                            ? true
                            : false
                        }
                      />
                      {validationEdit.touched.gst_class_name &&
                      validationEdit.errors.gst_class_name ? (
                        <FormFeedback type="invalid">
                          {validationEdit.errors.gst_class_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
  <Label>
    Percentage <span className="text-danger">*</span>
  </Label>
  <Input
    name="percentage"
    type="number"
    placeholder="Enter percentage"
    step="0.01"
    onChange={validationEdit.handleChange}
    onBlur={validationEdit.handleBlur}
    value={validationEdit.values.percentage || ""}
    invalid={
      validationEdit.touched.percentage &&
      validationEdit.errors.percentage
        ? true
        : false
    }
  />
  {validationEdit.touched.percentage &&
  validationEdit.errors.percentage ? (
    <FormFeedback type="invalid">
      {validationEdit.errors.percentage}
    </FormFeedback>
  ) : null}
</div>

                    <div className="mb-3">
                      <Label>Status</Label>
                      <FormGroup switch>
                        <Label className="">
                          {" "}
                          {validationEdit.values.status === true ? "" : ""}
                          <Input
                            name="status"
                            type="switch"
                            role="switch"
                            checked={validationEdit.values.status === true}
                            onClick={validationEdit.handleChange}
                            onBlur={validationEdit.handleBlur}
                            value={validationEdit.values.status || true}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        <i className="mdi mdi-content-save-outline" />
                        &nbsp; Update
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Gst
